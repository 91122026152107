<template>
	<GenreTab @changeGenre="changeGenre"></GenreTab>
	<div class="list_box_wrap" v-if="itemList.length > 0">
		<div class="list_box" v-for="(item, index) in itemList" :key="index">
			<div class="list_box_img_wrap">
				<img
					v-if="checkAdultUser(item)"
					class="cursor_pointer thumbs"
					@click="goContentDetailPage(item)"
					alt="Opus Thumbnail"
					:src="`${$image_host}/contents/${item.thumb_path}`"
				/>
				<img
					v-else
					class="cursor_pointer thumbs"
					@click="goContentDetailPage(item)"
					alt="Opus Thumbnail"
					src="@/assets/images/adult_thumbnail.png"
				/>

				<div class="tag_wrap">
					<div class="tags">
						<div class="tag" v-for="(tag, index) in item.hash_tag.split(',').slice(0, 2)" :key="index">
							{{ tag }}
						</div>
					</div>

					<div class="tag"><i class="bx bxs-heart"></i>{{ item.fav_cnt.toLocaleString() }}</div>
					<div v-if="item.new_yn === 'Y'" class="tag"><i class="bx bx-party"></i>신작</div>
					<div class="tag2" v-if="item.evt_yn === 'Y'">이벤트중</div>
				</div>
			</div>
			<div class="list_box_text_wrap">
				<div class="play_img cursor_pointer" @click="goContentDetailPage(item)">
					<img alt="" src="@/assets/images/icon_play.png" />
				</div>
				<h4>{{ item.opus_title }}</h4>
				<div class="name_box_wrap">
					<ul>
						<li>
							작가 : <span>{{ item.exposure_author }}</span>
						</li>
						<li>
							성우 : <span>{{ item.exposure_voice_actor }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="no_data" v-else>
		<span class="ic"></span>
		<span class="tit">Sorry</span>
		<p>데이터가 없습니다.</p>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ListByGenreStore } from '@/store/contentStore';
import GenreTab from '@/shared-components/GenreTab.vue';
import goContentDetailPage from '@/common-methods/goContentDetailPage';
import { checkAdultUser } from '@/common-methods/checkAccount';

const store = ListByGenreStore();
const { itemList, genre } = storeToRefs(store); // itemList라는 state를 반응형으로 가져옴
store.setGenre('ALL');
store.getHomeGenreItems(); // 기본 세팅으로 API 한번 호출

// 장르 탭 emit 함수
const changeGenre = (genre) => {
	store.setGenre(genre);
	store.getHomeGenreItems();
};
</script>

<style scoped></style>

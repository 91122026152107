<template>
	<div class="bnn_top_wrap">
		<div class="tit_wrap">
			<h2>실시간 랭킹</h2>
			<button @click="$router.push('/sub/ranking')">
				<img alt="" src="@/assets/images/icon_go_arrow_line.png" />
			</button>
		</div>
		<div class="realtime">
			<div class="realtime_day">{{ day }}</div>
			<span class="realtime_time">{{ second }}</span>
			<i class="bx bx-revision" style="transition: all ease 1s" @click="refreshCurrentTime"></i>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { HomeRealTimeRankingStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';

const store = HomeRealTimeRankingStore();

const { date } = storeToRefs(store);
const rotate = ref(720); // 회전 각도 변수
const rotateStatus = ref(false); // 회전 이벤트 실행중 여부

// 랭킹 새로고침 버튼 클릭 시
const refreshCurrentTime = (e) => {
	const rotateEvent = setTimeout(() => {
		rotateStatus.value = false;
	}, 1000);
	if (!rotateStatus.value) {
		e.target.style.transform = `rotate(${rotate.value}deg)`;
		rotate.value += 720;
		rotateStatus.value = true;
		store.getHomeRealTimeRanking();
	} else {
		window.clearTimeout(rotateEvent);
	}
};

// 시간 업데이트 시 출력 시간 변경
const day = computed(() => {
	return date.value.format('YYYY. MM. DD');
});
const second = computed(() => {
	return date.value.format('HH : mm');
});
</script>

<style></style>

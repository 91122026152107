<template>
	<div>
		<div class="content_wrap">
			<MainBanner></MainBanner>
			<div class="content_area_wrap">
				<MainGenreArea></MainGenreArea>
				<div class="half_box_content_wrap">
					<MainNewArea></MainNewArea>
					<MainRankingArea></MainRankingArea>
				</div>
				<MainEventArea></MainEventArea>
			</div>
		</div>
		<component :is="store.advice_pw_modal ? AdvicePw : null" />
		<component :is="store.find_pw_clear_modal ? FindPwClear : null" />
	</div>
</template>

<script setup>
import { computed, onMounted, toRefs } from 'vue';
import { userInfoStore } from '@/store/UserStore';
import jwt from '@/api/LocalStorage';
import axios from 'axios';

import MainBanner from '@/components/home/MainBanner.vue';
import MainGenreArea from '../components/home/MainGenreArea.vue';
import MainNewArea from '../components/home/MainNewArea.vue';
import MainEventArea from '../components/home/MainEventArea.vue';
import MainRankingArea from '@/components/home/MainRankingArea.vue';
import AdvicePw from '@/components/membership/modal/AdvicePw.vue';
import FindPwClear from '@/components/membership/modal/FindPwClear.vue';

const store = userInfoStore();
</script>

<style></style>

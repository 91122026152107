<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>비밀번호 변경 기한 만료</h2>
				<i @click="store.advice_pw_modal = false" class="bx bx-x"></i>
			</div>
			<div class="mypage_tit_wrap border_b_none">
				<p>비밀번호 재설정을 위해 회원 가입 당시 사용하였던 본인의 이메일 주소를 입력해 주세요.</p>
			</div>
			<div class="input_btn_wrap">
				<div class="item_id w_100">
					<input type="text" id="id" v-model="find_pw_email" placeholder="이메일 주소를 입력해 주세요." />
				</div>
			</div>
			<p id="find_adpw_temp"></p>
			<div class="notice">비밀번호 재설정 링크를 해당 이메일 주소로 보내드립니다.</div>
			<div class="btn_wrap_line">
				<button @click="store.advice_pw_modal = false" type="button" class="btn_basic btn_grey_bg">
					변경안함
				</button>
				<button @click="pw_ip_send()" type="button" id="adpw_send_btn" class="btn_basic btn_green_bg">
					확인
				</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { userInfoStore } from '@/store/UserStore';
import { EMAIL_REGI } from '../Register';
import ajax from '@/api/api-manager';
import { ref } from 'vue';

const find_pw_email = ref('');
const store = userInfoStore();

//PW기한 만료 시 변경할 링크메일 발송
const pw_ip_send = () => {
	const email_ck = EMAIL_REGI.test(find_pw_email.value);
	const find_pw_temp = document.querySelector('#find_adpw_temp');
	if (find_pw_email.value !== '' && email_ck) {
		const pw_send_btn = document.querySelector('#adpw_send_btn');
		pw_send_btn.disabled = true;
		if (pw_send_btn.disabled) {
			alert('변경메일 발송이 완료되었습니다.');
			find_pw_temp.innerHTML = '발송중입니다. 창을 닫지 말아주세요!';
			find_pw_temp.style.color = 'blue';
		}
		//유효성검사 진행 후 링크 발송
		ajax('user/find_pw', find_pw_email.value)
			.then((response) => {
				store.advice_pw_modal = false;
				store.find_pw_clear_modal = true;
			})
			.catch((error) => {
				if (error.response.data.code === 'M000') {
					find_pw_temp.innerHTML = '등록된 아이디가 없습니다 !';
					find_pw_temp.style.color = '#ff0000';
					pw_send_btn.disabled = false;
				}
			});
	} else {
		find_pw_temp.innerHTML = '올바른 이메일 주소가 아닙니다!';
	}
};
</script>
<style scope>
#find_adpw_temp {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>

<template>
	<div class="ranking_list_wrap" v-if="itemList.length > 0">
		<div class="rank_box" v-for="(item, index) in itemList" :key="index" @click="goContentDetailPage(item)">
			<div class="numb_wrap">
				<span class="now">{{ item.ranking }}</span>
				<div>
					<span class="change"
						><i class="bx bxs-up-arrow up" v-if="calcRanking(item.pre_ranking, item.ranking) === 'up'"></i
						><i
							class="bx bxs-down-arrow down"
							v-if="calcRanking(item.pre_ranking, item.ranking) === 'down'"
						></i
						><i
							class="bx bx-minus nochange"
							v-if="calcRanking(item.pre_ranking, item.ranking) === 'same'"
						></i
					></span>
					<span v-if="calcRanking(item.pre_ranking, item.ranking) === 'up'" class="step up">{{
						calcStep(item.pre_ranking, item.ranking)
					}}</span>
					<span v-if="calcRanking(item.pre_ranking, item.ranking) === 'down'" class="step down">{{
						calcStep(item.pre_ranking, item.ranking)
					}}</span>
				</div>
			</div>
			<div class="thumb_wrap">
				<img alt="thumbnail" :src="`${$image_host}/contents/${item.thumb_path}`" />
			</div>
			<div class="noveltit">{{ item.opus_title }}</div>
			<div class="names_wrap">
				<ul class="names_ul">
					<li>
						작가 : <span>{{ item.exposure_author }}</span>
					</li>
					<li>
						성우 : <span>{{ item.exposure_voice_actor }}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="no_data" v-else>
		<span class="ic"></span>
		<span class="tit">Sorry</span>
		<p>데이터가 없습니다.</p>
	</div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { HomeRealTimeRankingStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import { calcRanking, calcStep } from '@/common-methods/computedValue';
import goContentDetailPage from '@/common-methods/goContentDetailPage';

// 페이지 로드 첫 리스트 호출
const store = HomeRealTimeRankingStore();
const { itemList } = storeToRefs(store);
store.getHomeRealTimeRanking();
</script>

<style scoped>
.thumb_wrap {
	width: 42px;
	height: 42px;
}
</style>

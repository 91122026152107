<template>
	<div class="new_bnn_img_wrap">
		<swiper
			:modules="modules"
			:navigation="{ nextEl: '.new_next', prevEl: '.new_prev' }"
			:loop="true"
			:speed="1000"
			:autoplay="{ delay: 3000, disableOnInteraction: false }"
			:slides-per-view="1"
			:space-between="50"
			@slideChange="onSlideChange"
			v-if="bannerList.length > 0"
		>
			<swiper-slide v-for="(item, index) in bannerList" :key="index">
				<div class="new_bnn_item_box">
					<img alt="" :src="`${$image_host}/image/${item.b_image_path}`" />
					<div class="right_info_wrap">
						<dl class="new_bnn_dl">
							<dt>{{ $convertGenreCode(item.genre) }}</dt>
							<dd>{{ item.opus_title }}</dd>
						</dl>
						<div class="new_bnn_tag_wrap">
							<div class="tag"><i class="bx bxs-right-arrow play"></i>{{ item.view_cnt }}</div>
							<div class="tag"><i class="bx bxs-heart"></i>{{ item.fav_cnt.toLocaleString() }}</div>
						</div>
						<p>
							{{ item.b_content }}
						</p>
						<div class="right_info_btn_wrap">
							<button
								@click="goContentDetailPage(item)"
								type="button"
								class="right_info_btn cursor_pointer"
							>
								<i class="bx bxs-right-arrow"></i><span>바로가기</span>
							</button>
						</div>
					</div>
				</div>
			</swiper-slide>
		</swiper>
		<div class="no_data" v-else>
			<span class="ic"></span>
			<span class="tit">Sorry</span>
			<p>데이터가 없습니다.</p>
		</div>
	</div>
</template>

<script setup>
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css';
import { HomeNewBannerStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import goContentDetailPage from '@/common-methods/goContentDetailPage';

const store = HomeNewBannerStore();
const { bannerList, activeIndex, totalIndex } = storeToRefs(store);

store.getHomeNewBanner();
const modules = [Autoplay, Navigation]; // Swiper 컴포넌트에 props로 들어감
// const onSwiper = (swiper) => {};   // swiper 로드 직후

const onSlideChange = (swiper) => {
	// 슬라이드 변경 시
	// console.log(swiper.realIndex);
	store.$patch({ activeIndex: swiper.realIndex });
};
</script>

<style></style>

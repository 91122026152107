<template>
	<div class="bnn_top_wrap">
		<div class="tit_wrap">
			<h2>신작소개</h2>
			<button @click="$router.push('/sub/new')">
				<img alt="" src="@/assets/images/icon_go_arrow_line.png" />
			</button>
		</div>
		<div class="slide_bnn_page_wrap">
			<div class="page_num">
				<span>{{ activeIndex + 1 }}</span
				>/{{ totalIndex }}
			</div>
			<div class="page_btn_wrap">
				<button class="page_btn_basic new_prev white_border">
					<img alt="" src="@/assets/images/left_arrow_b.png" />
				</button>
				<button class="page_btn_basic new_next white_border">
					<img alt="" src="@/assets/images/right_arrow_b.png" />
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { HomeNewBannerStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
const store = HomeNewBannerStore();
const { activeIndex, totalIndex } = storeToRefs(store);
</script>

<style></style>

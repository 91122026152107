<template>
	<!-- <div class="main_bnn_wrap"> -->
	<swiper
		:modules="modules"
		:navigation="{ nextEl: '.next_btn', prevEl: '.prev_btn' }"
		:loop="true"
		:speed="1000"
		:autoplay="{ delay: 3000, disableOnInteraction: false }"
		:slides-per-view="1"
		:space-between="0"
		@slideChange="onSlideChange"
		v-if="bannerList"
		class="main_bnn_wrap"
	>
		<swiper-slide v-for="(item, index) in bannerList" :key="index">
			<div
				class="main_bnn_box"
				:style="{ backgroundImage: `url(${$image_host}/image/${item.b_background_image_path})` }"
			>
				<div class="main_bnn_txt_wrap">
					<div class="main_bnn_txt_top">
						<dl>
							<dt>{{ item.b_title }}</dt>
							<dd>{{ item.b_sub_title }}</dd>
						</dl>
						<p>
							{{ item.b_content }}
						</p>
					</div>
					<div class="main_bnn_txt_btm">
						<div class="main_bnn_txt_btn_wrap">
							<button class="btn_basic btn_white_border" @click="goContentDetailPage(item)">
								<span>바로가기</span>
							</button>
							<!-- <button class="btn_basic btn_green_border">
                                <span>장바구니</span>
                            </button> -->
						</div>
					</div>
				</div>
				<div class="main_bnn_img_wrap">
					<img alt="banner" :src="`${$image_host}/image/${item.b_image_path}`" />
				</div>
			</div>
		</swiper-slide>
		<div class="main_bnn_page_wrap">
			<div class="page_num">
				<span>{{ activeIndex + 1 }}</span
				>/{{ totalIndex }}
			</div>
			<div class="page_btn_wrap">
				<button class="page_btn_basic white_border prev_btn">
					<img alt="" src="@/assets/images/left_arrow.png" />
				</button>
				<button class="page_btn_basic white_border next_btn">
					<img alt="" src="@/assets/images/right_arrow.png" />
				</button>
			</div>
		</div>
	</swiper>

	<!-- </div> -->
</template>

<script setup>
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css';
import { HomeMainBannerStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import goContentDetailPage from '@/common-methods/goContentDetailPage';

const store = HomeMainBannerStore();
const { bannerList, activeIndex, totalIndex } = storeToRefs(store);

store.getHomeMainBanner();
const modules = [Autoplay, Navigation]; // Swiper 컴포넌트에 props로 들어감
// const onSwiper = (swiper) => {};   // swiper 로드 직후

const onSlideChange = (swiper) => {
	// 슬라이드 변경 시
	// console.log(swiper.realIndex);
	store.$patch({ activeIndex: swiper.realIndex });
};
</script>

<style scoped>
@media (max-width: 638px) {
	.main_bnn_box {
		/* display: flex;
	flex-direction: column; */

		flex-direction: column !important;
		height: 400px !important;
	}

	.main_bnn_page_wrap {
		position: absolute;
		right: 10px;
		bottom: 30px;
		z-index: 1;
		color: white;
	}
}
@media (min-width: 639px) {
	.main_bnn_page_wrap {
		position: absolute;
		left: 40px;
		bottom: 10px;
		z-index: 1;
		color: white;
	}
}

@media (min-width: 1053px) {
	.main_bnn_page_wrap {
		position: absolute;
		left: 40px;
		bottom: 20px;
		z-index: 1;
		color: white;
	}
}
</style>
